export const isPreviewMode = (remoteConfig: string) => {
	const url = new URL(window.location.href);
	return url.searchParams.get(`preview-${remoteConfig}`);
};

export const initChatService = (chatServiceOptions: any) => {
	window.chatServiceOptions = chatServiceOptions;
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = 'https://js.chatservice.co/v0/chatservice-no-polyfill.js';
	document?.head?.append(script);
};

// TODO: More proper checks here regarding non-empty objects?
export const shouldRenderDynamicEngagement = (config: any) => config?.dynamicEngagement;
export const shouldRenderChatService = (config: any) => config?.chatService;
