import {
	isPreviewMode,
	shouldRenderDynamicEngagement,
	shouldRenderChatService,
	initChatService,
} from './tools';

// TODO: Move this to .d.ts file
declare global {
	interface Window {
		customerServiceOptions: any;
		chatServiceOptions: any;
	}
}

const initialize = async (customerServiceOptions: any = {}) => {
	const { remoteConfig } = customerServiceOptions;
	// Check if remote config is present
	if (typeof remoteConfig === 'string' && remoteConfig.length > 20) {
		const remoteConfigUrl = isPreviewMode(remoteConfig)
			? `https://public-chatservice.s3.eu-west-2.amazonaws.com/preview/${remoteConfig}-customer-service-config-preview.json`
			: `https://dist.chatservice.co/${remoteConfig}-customer-service-config.json`;

		// Fetch remote config
		const config = await fetch(`${remoteConfigUrl}`)
			.then(async (response) => response.json())
			.catch(() => {
				throw new Error("[CustomerService] Couldn't load config");
			});

		if (config) {
			if (shouldRenderDynamicEngagement(config)) {
				import('./init-react').then((module) => {
					const initReact = module.default;
					initReact(config);
				});
			}
			if (shouldRenderChatService(config)) {
				initChatService(config?.chatService);
			}
		}
	}
};

initialize(window?.customerServiceOptions);
